import React from "react";
import { QMoneyhubTransaction as QMT } from "app/queries";
import {
  DefaultTableComponent,
  T_DefaultTableComponentProps,
  TDF,
} from "app/components/table";
import { pallete } from "app/styles";
import {
  T_CryptohubTransactionTableData,
  useCryptoHubTransactionTableRequest,
} from "./CryptoHubDashboardQueries";
import { useAccountDetailsQuery } from "app/queries/UserAccountQueries";

const getStatusColor = (status: string): any => {
  switch (status) {
    case "-1":
      return pallete.selections.red;
    case "0":
      return pallete.selections.yellow;
    case "1":
      return pallete.selections.blue;
    case "2":
      return pallete.selections.green;
    default:
      return pallete.selections.brown;
  }
};

const renderFunction = (
  data: T_CryptohubTransactionTableData
): React.JSX.Element[] => {
  const transactionStatusColour = getStatusColor(
    data.status_id?.toString() || ""
  );
  return [
    <TDF.TextContent value={data.sender_email} />,
    <TDF.NumericContent value={`${data.amount}`} />,
    <TDF.NumericContent value={`${data.fees}`} />,
    <TDF.TextContent value={data.currency_symbol} />,
    <TDF.TextContent value={data.fireblocks_asset_id} />,
    <TDF.NumericContent value={data.asset_quantity} />,
    <TDF.NumericContent value={data.received_quantity} />,
    <TDF.StatusBadge
      statusText={data.status_name}
      color={transactionStatusColour}
    />,
    // <TDF.DateTimeContent dateTime={(data?.created_at ? data.created_at.toString() : '')} />,
  ];
};

const cryptoHubOrdersDataMap = {
  Email: "sender_email",
  Amount: "amount",
  Fees: "fees",
  Currency: "currency_symbol",
  "Asset Name": "fireblocks_asset_id",
  "Asset Quantity": "asset_quantity",
  "Received Quantity": "received_quantity",
  Status: "status_name",
  // "Date": "created_at"
};

const CryptoHubTransactionTable = () => {
  const cryptohubOrdersQuery = useCryptoHubTransactionTableRequest([
    0, 1, 2, 3, -1,
  ]);
  const userdetails = useAccountDetailsQuery();

  const headings: string[] = Object.keys(cryptoHubOrdersDataMap);

  const tableData = cryptohubOrdersQuery.isResolved
    ? cryptohubOrdersQuery.result.filter(
        (data) => data.receiver_email === userdetails.result?.email
      )
    : [];

  const tableComponentProps: T_DefaultTableComponentProps = {
    title: "Crypto Hub Orders",
    headings: headings,
    isLoading: cryptohubOrdersQuery.isResolving || userdetails.isResolving,
    initialRowsPerPage: 10,
    tableData: tableData,
    renderFunction: renderFunction,
    searchOptions: {
      searchKeys: [
        "sender_email",
        "currency_symbol",
        "asset_name",
        "asset_quantity",
        "status_name",
        "amount",
      ],
    },
    downloadOptions: {
      fileName: "crypto-hub-orders.csv",
      dataMap: cryptoHubOrdersDataMap,
    },
  };

  return <DefaultTableComponent {...tableComponentProps} />;
};

export default CryptoHubTransactionTable;
