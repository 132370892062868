import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { pallete, styles } from "app/styles";
import { getAxiosAPI } from "app/utils/axiosApiRequests";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import { makeStyles } from "tss-react/mui";
import { DownloadRounded } from "@mui/icons-material";
import { downloadCSVFile } from "app/utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: "14px",
  color: pallete.primaryBlack,
  fontFamily: "Montserrat",
  padding: theme.spacing(1),
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: "20px 0 !important",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: `${theme.palette.action.selected}!important`, // Use !important to override inline styles if necessary
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: "5px 10px",
  fontSize: "14px",
  textTransform: "none",
  borderRadius: "4px",
  width: "100%",
  color: pallete.primaryWhite,
  backgroundColor: pallete.primaryPurple,
}));

const downloadCSV = (data: any[], filename: string) => {
  if (!data.length) {
    return;
  }
  const headings = Object.keys(data[0]);
  const data_values = data.map((item) => Object.values(item));
  const fileName = filename || "export";
  const mergedData: any[] = [headings, ...data_values].map(
    (row) => `${row.join(",")}`
  );
  const csvContent: string = mergedData.join("\r\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const Fiat_table = () => {
  const { classes } = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userTrades, setUserTrades] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    loadTrades();
  }, []);

  const loadTrades = async () => {
    setLoading(true);
    await getAxiosAPI("/user-account/trade", true)
      .then((res: any) => {
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          // console.log(res.data.data);
          setUserTrades(res.data.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to fetch details!");
        setShowSnackbar(true);
        setSeverity("error");
      });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const approveTrade = async () => {
    setLoading(true);
    setOpen(false);
    const enpoint = `trade/${selectedRow.id}/confirm-otc-price`;
    await getAxiosAPI(enpoint, true)
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        }
        loadTrades();
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to update the trade action");
        setSeverity("error");
        setShowSnackbar(true);
      });
  };

  return (
    <>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Box className={classes.mainBox}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: 2,
            width: "100%",
          }}
        >
          <Box
            sx={{
              padding: "12px",
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}
            onClick={() => {
              downloadCSV(userTrades, "fiat-trade");
            }}
          >
            <DownloadRounded />
            <Typography variant="h6" color="primary">
              Download
            </Typography>
          </Box>
        </Box>
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead sx={{ backgroundColor: "#F7F6F9" }}>
              <TableRow>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Quantity</StyledTableCell>
                <StyledTableCell align="center">Pay</StyledTableCell>
                <StyledTableCell align="center">Fees</StyledTableCell>
                <StyledTableCell align="center">Assets</StyledTableCell>
                <StyledTableCell align="center">Currency</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">UID</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userTrades
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.created_at}
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      style={
                        row.status === 1
                          ? { color: pallete.selections.green, fontWeight: 600 }
                          : {
                              color: pallete.selections.yellow,
                              fontWeight: 600,
                            }
                      }
                    >
                      {row.status_id === 1
                        ? "Complete"
                        : row.status_id === 0
                        ? "Pending"
                        : "Cancelled"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.quantity}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.amount}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.fees}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.asset}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.currency}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <StyledButton
                        onClick={() => {
                          setSelectedRow(row);
                          setOpen(true);
                        }}
                        disabled={row.status !== 0}
                        variant="contained"
                      >
                        {row.status === 0 ? "Approve" : "Done"}
                      </StyledButton>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.uid ? (
                        row?.uid_url ? (
                          <Link
                            href={`${row.uid_url}/${row.uid}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {row.uid}
                          </Link>
                        ) : (
                          row.uid
                        )
                      ) : (
                        "N/A"
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={userTrades.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-selectIcon":
              {
                color: "rgba(0, 0, 0, 0.54)",
              },
            ".MuiTablePagination-displayedRows": {
              color: "rgba(0, 0, 0, 0.54)",
            },
            ".MuiTablePagination-actions": {
              color: "rgba(0, 0, 0, 0.54)",
            },
          }}
        />
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4" color="primary">
            {"Trade Actions"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h6" color="primary">
              Are you sure you want to approve this Trade.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton
            variant="contained"
            sx={{ backgroundColor: "grey" }}
            onClick={() => setOpen(false)}
            autoFocus
          >
            Cancel
          </StyledButton>
          <StyledButton variant="contained" onClick={() => approveTrade()}>
            Yes
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Crypto_table = () => {
  const { classes } = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userTrades, setUserTrades] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    loadTrades();
  }, []);

  const loadTrades = async () => {
    setLoading(true);
    await getAxiosAPI("/user-account/crypto-trade", true)
      .then((res: any) => {
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          // console.log(res.data.data);
          setUserTrades(res.data.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to fetch details!");
        setShowSnackbar(true);
        setSeverity("error");
      });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const approveTrade = async () => {
    setLoading(true);
    setOpen(false);
    const enpoint = `trade/${selectedRow.id}/confirm-otc-price`;
    await getAxiosAPI(enpoint, true)
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        }
        loadTrades();
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to update the trade action");
        setSeverity("error");
        setShowSnackbar(true);
      });
  };

  return (
    <>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Box className={classes.mainBox}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: 2,
            width: "100%",
          }}
        >
          <Box
            sx={{
              padding: "12px",
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}
            onClick={() => {
              downloadCSV(userTrades, "crypto-trade");
            }}
          >
            <DownloadRounded />
            <Typography variant="h6" color="primary">
              Download
            </Typography>
          </Box>
        </Box>
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead sx={{ backgroundColor: "#F7F6F9" }}>
              <TableRow>
                <StyledTableCell align="center">User Account</StyledTableCell>
                <StyledTableCell align="center">Asset In</StyledTableCell>
                <StyledTableCell align="center">Asset Out</StyledTableCell>
                <StyledTableCell align="center">
                  Conversion Rate
                </StyledTableCell>
                <StyledTableCell align="center">Quantity In</StyledTableCell>
                <StyledTableCell align="center">Quantity Out</StyledTableCell>
                <StyledTableCell align="center">
                  Fees (In Asset)
                </StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">UID</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userTrades
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="center">
                      {row.user_account}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.asset_in}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.asset_out}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.conversion}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.quantity_in}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.quantity_out}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.quantity_fees_in}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={
                        row.status === 1
                          ? { color: pallete.selections.green, fontWeight: 600 }
                          : {
                              color: pallete.selections.yellow,
                              fontWeight: 600,
                            }
                      }
                    >
                      {row.status}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.created_at
                        ? new Date(row.created_at).toLocaleDateString()
                        : "N/A"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <StyledButton
                        onClick={() => {
                          setSelectedRow(row);
                          setOpen(true);
                        }}
                        disabled={row.status_id !== 0}
                        variant="contained"
                      >
                        {row.status_id === 0 ? "Approve" : "Done"}
                      </StyledButton>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.uid ? (
                        row?.uid_url ? (
                          <Link
                            href={`${row.uid_url}/${row.uid}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {row.uid}
                          </Link>
                        ) : (
                          row.uid
                        )
                      ) : (
                        "N/A"
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={userTrades.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-selectIcon":
              {
                color: "rgba(0, 0, 0, 0.54)",
              },
            ".MuiTablePagination-displayedRows": {
              color: "rgba(0, 0, 0, 0.54)",
            },
            ".MuiTablePagination-actions": {
              color: "rgba(0, 0, 0, 0.54)",
            },
          }}
        />
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4" color="primary">
            {"Trade Actions"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h6" color="primary">
              Are you sure you want to approve this Trade.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton
            variant="contained"
            sx={{ backgroundColor: "grey" }}
            onClick={() => setOpen(false)}
            autoFocus
          >
            Cancel
          </StyledButton>
          <StyledButton variant="contained" onClick={() => approveTrade()}>
            Yes
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TransactionsTableHelper = ({ type }: { type?: number }) => {
  if (type === 0) {
    return <Fiat_table />;
  } else if (type === 1) {
    return <Crypto_table />;
  }
  return <Fiat_table />;
};

const TransactionsTable = () => {
  const { classes } = useStyles();

  const [tableState, setTableState] = useState(0);

  const table_tabs = [
    { id: 0, label: "Fiat trade", url: "/user-account/trade" },
    { id: 1, label: "Crypto trade", url: "/user-account/crypto-trade" },
  ];

  const handleChangetableState = (event: any) => {
    setTableState(event.target.value);
  };

  return (
    <Card className={classes.commonCardsTable}>
      <Box className={classes.commonCardsHBox}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 2,
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            color="primary"
            fontWeight={"bold"}
            sx={{ marginLeft: "20px" }}
          >
            Transactions
          </Typography>
          <Select
            value={tableState}
            onChange={handleChangetableState}
            variant="outlined"
            sx={{
              width: "150px",
              color: pallete.primaryPurple,
              borderColor: pallete.primaryPurple,
            }}
          >
            {table_tabs.map((tab) => (
              <MenuItem key={tab.id} value={tab.id}>
                {tab.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <TransactionsTableHelper type={tableState} />
    </Card>
  );
};
export default TransactionsTable;

const useStyles = makeStyles()((theme) => {
  return {
    mainBox: {
      padding: "12px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
    },
    octBtnBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    otcGrid: {
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
    },
    otcBtn: {
      ...styles.appButton,
      margin: "0 10px",
    },
    otcBox: {},

    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    successImgBox: {
      ...styles.flexDCC,
      alignItems: "center",
    },
    flexRow: {
      ...styles.flexDRS,
      padding: "8px",
    },
    topCard1: {
      padding: "0 20px 10px 0",
      [theme.breakpoints.down("lg")]: {
        padding: "10px",
      },
    },
    topHeadingWeb: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    tradeBtn: {
      width: "100%",
      backgroundColor: pallete.primaryPurple,
      color: pallete.primaryWhite,
      textTransform: "none",
      borderRadius: "4px",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22.4px",
      padding: "15px 0",
      marginTop: "20px",
      "&:hover": {
        backgroundColor: pallete.primaryPurple,
        color: pallete.primaryWhite,
      },
    },
    tradeBtn0: {
      width: "100%",
      color: pallete.primaryPurple,
      backgroundColor: pallete.primaryWhite,
      textTransform: "none",
      borderRadius: "4px",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22.4px",
      padding: "15px 0",
      margin: "10px 3px",
    },
    tradeBtn1: {
      width: "100%",
      backgroundColor: pallete.primaryPurple,
      color: pallete.primaryWhite,
      textTransform: "none",
      borderRadius: "4px",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22.4px",
      padding: "15px 0",
      margin: "10px 3px",
      "&:hover": {
        backgroundColor: pallete.primaryPurple,
        color: pallete.primaryWhite,
      },
    },
    inputOutlined: {
      width: "100%",
      margin: "10px 0",
    },
    grphnIcons: {
      height: "15px",
      width: "15px",
      padding: "2px",
    },
    ratingAmtN: {
      color: "#E13A05",
      fontWeight: 600,
    },
    ratingAmtP: {
      color: "#00E069",
      fontWeight: 600,
    },
    plusRating: {
      borderRadius: "4px",
      backgroundColor: "#E5FFF1",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: "2px 5px",
    },
    minusRating: {
      borderRadius: "4px",
      backgroundColor: "#FFF0EB",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: "2px 5px",
    },
    card2Box1: {
      ...styles.flexDRC,
      justifyContent: "space-between",
    },
    bottomDataBox: {
      padding: "25px",
    },
    piceBOX: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    selectTokenBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    btnsBS: {
      width: "100%",
      padding: "15px 5px",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 600,
      "&:hover": {
        backgroundColor: "#E0E3F2",
      },
    },
    btnsBSActive: {
      width: "100%",
      padding: "15px 5px",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 600,
      backgroundColor: "#E0E3F2",
    },
    buysellBtnBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "stretch",
    },
    buysellCard: {
      border: `1px soild ${pallete.primaryBlack}`,
      width: "110%",
      margin: "0 15px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      borderRadius: "8px",
      [theme.breakpoints.down("lg")]: {
        margin: "10px 0",
      },
    },
    buysellCardR: {
      border: `1px soild ${pallete.primaryBlack}`,
      width: "100%",
      margin: "0 15px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      borderRadius: "8px",
      padding: "15px 20px",
      [theme.breakpoints.down("lg")]: {
        margin: "10px 0",
      },
    },
    tabsText: {
      fontSize: "16px",
      fontWeight: 600,
      color: pallete.selections.blue,
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
      },
    },
    startIconBoxCal: {
      height: "24px",
      width: "24px",
    },
    commonCardsTable: {
      padding: "12px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      width: "100%",
      margin: "20px 0",
    },
    startIconBox: {
      backgroundColor: pallete.primaryGreen,
      borderRadius: "50%",
      padding: "5px",
      height: "15px",
      width: "15px",
    },
    iconsBtn: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      // lineHeight: "140%",
      padding: "10px 20px",
      borderRadius: "8px",
    },
    startIconBoxEth: {
      borderRadius: "50%",
      // padding: "5px",
      height: "24px",
      width: "24px",
    },
    commonCardsHBox: {
      ...styles.flexDRS,
      padding: "20px 0",
    },
    commonCards: {
      padding: "12px",
      borderRadius: "8px",
      width: "100%",
      // height: "90%",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      [theme.breakpoints.down("lg")]: {
        margin: "10px 0",
      },
      margin: "10px 0",
    },
  };
});
