import { makeStyles, pallete } from "app/styles";

export const useIntegrationLayoutStyles = makeStyles()((theme) => {
  return {
    centerItemBox: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    innerBoxOther: {
      margin: "10px 40px",
      padding: "30px 100px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      [theme.breakpoints.down("md")]: {
        margin: "10px 20px",
        padding: "15px",
      },
    },
    iconWeb: {
      height: "45px",
    },
    imagBoxOther: {
      height: "50px",
      padding: "10px",
      display: "flex",
      // justifyContent: "",
      // gap: "23vw",
      [theme.breakpoints.down("lg")]: {
        // display: "none",
        gap: 0,
      },
    },
    successIcon: {
      height: "100px",
    },
    mainBoxStepOne: {
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100vh", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        // display: "none",
      },
    },
    footerDivider: {
      width: "100%",
      backgroundColor: pallete.primaryBlack,
    },
    footerBox: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      padding: "20px",
    },
    footerContent: {
      textAlign: "center",
      fontSize: "12px",
      fontStyle: "italic",
    },
    footerCopyright: {
      fontWeight: "bold",
    },
    LanguageDropDown: {
      position: "relative",
      right: "auto",
      margin: "10px",
      left: "79%",
      [theme.breakpoints.between("sm", "md")]: {
        left: "55%",
        // display: "none", // Uncomment if you want to hide the element
      },
      [theme.breakpoints.down("sm")]: {
        left: "28%",
      },
    },
  };
});
