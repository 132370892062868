import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { useIntegrationLayoutStyles } from "./IntegrationStyles";
import LanguageDropdown from "app/components/language_switcher/LanguageDropDown";

type T_IntegrationViewProps = {
  activeComponent: React.JSX.Element;
};

export const IntegrationView = ({
  activeComponent,
}: T_IntegrationViewProps): React.JSX.Element => {
  const { classes } = useIntegrationLayoutStyles();

  return (
    <Box className={classes.centerItemBox}>
      <Box className={classes.imagBoxOther}>
        <Box
          component="img"
          className={classes.iconWeb}
          src="/assets/logos/tradeBp.svg"
          alt="Tradebp Logo"
          //   sx={{ width: "40vw" }}
        />
        <Box className={classes.LanguageDropDown}>
          <LanguageDropdown />
        </Box>
      </Box>
      <Box className={classes.innerBoxOther}>{activeComponent}</Box>
      <Box className={classes.footerBox}>
        <Divider className={classes.footerDivider} />
        <Typography className={classes.footerCopyright}>
          Copyright © 2024 TradeBP, LDA. All rights reserved.
        </Typography>
        <Typography className={classes.footerContent}>
          TradeBP, LDA is a company registered in Portugal with registration
          number 516983520. TradeBP, LDA is authorized by the Central Bank of
          Portugal as a VASP (Virtual Assets Services Provider) to provide
          trading and custody solutions in virtual assets. Virtual assets,
          including cryptocurrencies, are subject to substantial price
          volatility, market risks, and cybersecurity vulnerabilities. The
          Client should gather all necessary information to comprehend these
          risks before using the Services and Products.
        </Typography>
      </Box>
    </Box>
  );
};
