import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useLocalization } from "../../hooks";
import { makeStyles } from "app/styles";

const languages = [
  { code: "en", name: "English" },
  { code: "pt", name: "Português" },
  { code: "zh", name: "Mandarin" },
];

export const LanguageDropdown = () => {
  const { languageCode, initializeLanguage } = useLocalization();
  // const { classes } = LanguageDropdownStyles();
  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const newLanguage = event.target.value;
    initializeLanguage(newLanguage);
  };

  return (
    <FormControl
      variant="outlined"
      size={"small"}
      // sx={{ position: "relative", right: "auto", left: "23%" }}
      // className={classes.LanguageDropDown}
    >
      <InputLabel id="language-select-label">Language</InputLabel>
      <Select
        labelId="language-select-label"
        value={languageCode}
        onChange={handleLanguageChange}
        label="Language"
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.code}
            value={lang.code}
            style={{ textAlign: "center" }}
          >
            <Box
              component="img"
              src={`/assets/logos/${lang.code}.png`}
              style={{ width: "35px", height: "20px", marginRight: "8px" }}
            />
            {lang.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageDropdown;

// export const LanguageDropdownStyles = makeStyles()((theme) => {
//   return {
//     LanguageDropDown: {
//       position: "relative",
//       right: "auto",
//       margin: "10px",
//       left: "80%",
//       [theme.breakpoints.between("sm", "md")]: {
//         left: "55%",
//         // display: "none", // Uncomment if you want to hide the element
//       },
//       [theme.breakpoints.down("sm")]: {
//         left: "28%",
//       },
//     },
//   };
// });
